import React, { createContext, useContext } from 'react'

interface BusinessTypeContextType {
  companyType: string | undefined
  setCompanyType: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const BusinessTypeContext = createContext<BusinessTypeContextType | undefined>(undefined)

export const useBusinessTypeContext = () => {
  const context = useContext(BusinessTypeContext)
  if (!context) {
    throw new Error('useBusinessTypeContext must be used within a BusinessTypeContext.Provider')
  }
  return context
}
