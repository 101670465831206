import { BusinessType } from '../types/enums/BusinessType'
import { SearchCompanyDetails } from '../types/SearchCompanyDetails'

export const filterByBusinessStructure = (
  businessStructure: BusinessType,
  data: SearchCompanyDetails[] | undefined
) => {
  if (data === undefined) return data

  switch (businessStructure) {
    case BusinessType.LimitedCompany:
      return data.filter((d) => d.auxiliaryIdentifiers.some((a) => a.isNationalId))
    case BusinessType.GovernmentEntity:
      return data.filter((d) => !d.auxiliaryIdentifiers.some((a) => a.isNationalId))
    case BusinessType.SoleTrader:
      return []
  }
}
