import { useQuery } from 'react-query'
import { apiCompanyInfoClient } from '../api/apiCompanyInfoClient'
import { retry } from '../api/apiBaseUrl'
import { SearchCompaniesRequest } from '../types/SearchCompaniesRequest'
import { AxiosResponse } from 'axios'
import { SearchCompanyDetails } from '../types/SearchCompanyDetails'
import { defaultCountryCode } from '../consts'
import { BusinessType } from '../types/enums/BusinessType'
import { filterByBusinessStructure } from '../utils/companyUtils'

export const fetchCompanyAutocompleteDataKey = 'fetch-company-autocomplete-data'

export const fetchCompanySearchDetails = async (
  companyInfo: string | undefined,
  minLength: number
): Promise<SearchCompanyDetails[]> => {
  if (!companyInfo || companyInfo.length <= minLength) return []

  const response = await apiCompanyInfoClient.post<SearchCompaniesRequest, AxiosResponse<SearchCompanyDetails[]>>(
    '/search',
    {
      fuzzyTerm: companyInfo,
      address: {
        country: defaultCountryCode
      }
    }
  )

  return response.data
}

export const useFetchCompanyAutocompleteData = (
  companyInfo: string | undefined,
  minLength: number,
  businessStructure: BusinessType
) => {
  const { data, isLoading } = useQuery<SearchCompanyDetails[], Error>(
    [fetchCompanyAutocompleteDataKey, companyInfo],
    () => fetchCompanySearchDetails(companyInfo, minLength),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

  return {
    isLoading,
    data: filterByBusinessStructure(businessStructure, data)
  }
}
