interface IUnleashApiKeyMapping {
  [Key: string]: string
}

export const stagingUnleashApiKey = 'default:development.e3ff958f3112d10250eb9349c5a1fcbc98641ff2ce4e8b990323f306'
export const productionUnleashApiKey = 'default:production.6a476955b2bffc837b2bda2d2a6e6dc5e180e584f5906681a9f9b3f1'

const keyMapping: IUnleashApiKeyMapping = {
  'payments.kriya.co': productionUnleashApiKey,
  'payments.kriya.dev': stagingUnleashApiKey,
  'localhost:3000': stagingUnleashApiKey
}

const unleashApiKey = (currentUrl: string): string => {
  const host = new URL(currentUrl).host
  return keyMapping[host]
}

export default unleashApiKey
